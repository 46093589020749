import * as React from 'react';
import { View, Image, useTheme } from '@aws-amplify/ui-react';
import logo from '../images/logo.png';
import clientLogo from '../images/fieldfisher.png'


export const authenticatorComponents = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>


        <Image
          alt="OptiSeat logo"
          src={logo}
        />
      </View>
    );
  },
}
