import '@aws-amplify/ui-react/styles.css';
import {
    List,
    Datagrid,
    TextField,
    NumberField,
    NumberInput,
    SimpleForm,
    Create,
    Edit,
    EditButton,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    useNotify,
    useRefresh,
    useRedirect,
    DateField,
    Show,
    SimpleShowLayout,
    required,
    useGetOne,
    useGetIdentity
} from "react-admin";
import { BackButton } from './BackButton';
import { ordinal_suffix_of, wordy_ordinal } from './variable_preferences';
import { DepartmentPlusSiteField } from "./Trainee";


const validatePreference = (values) => {
    const errors = {};
    console.warn("values", values)
    for (let priority = 0; priority < process.env.REACT_APP_NUM_OF_PREFRENCES; priority++) {
        if (!values.departmentID[priority]) {
            errors['departmentID.' + priority] = "Must enter a " + wordy_ordinal(priority + 1) + " preference"
        }
    }

    return errors
}
export const ManyPreferenceCreate = (props) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();

    const onSuccess = () => {
        notify(`Changes saved`);
        redirect('/');
        refresh();
    };
    const { data, isLoading } = useGetIdentity();
    const traineeData = useGetOne('trainees', { id: data?.id + "::" + data?.id })
    if (!isLoading) {
        const onError = onSuccess;
        var preferencesInput = []
        for (let priority = 0; priority < process.env.REACT_APP_NUM_OF_PREFRENCES; priority++) {
            preferencesInput.push(<ReferenceInput label={ordinal_suffix_of(priority + 1) + " Preference"} source={"departmentID." + priority} reference="departments" filter={{ departmentsBySiteIDAndName: { siteID: traineeData.data?.siteID } }} >
                <SelectInput optionText="name" label={ordinal_suffix_of(priority + 1) + " Preference"} />
            </ReferenceInput >)
        }
        return (
            <Create {...props} mutationOptions={{ onError, onSuccess, meta: { many: true } }} actions={<BackButton />}>
                <SimpleForm validate={validatePreference}>
                    {preferencesInput}
                </SimpleForm>
            </Create>
        )
    }
}

export const PreferenceCreate = (props) => (
    <Create {...props} actions={<BackButton />}>
        <SimpleForm>
            <ReferenceInput label="Department" source="departmentID" reference="departments" filter={{ departmentsByTypeAndName: { type: "Department" } }}>
                <SelectInput optionText="name" label="Department" />
            </ReferenceInput>
            <NumberInput source="priority" defaultValue="1" />
        </SimpleForm>
    </Create>
);

export const PreferenceEdit = (props) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();


    const onSuccess = () => {
        notify(`Changes saved`);
        redirect('/trainees');
        refresh();
    };
    return (
        <Edit {...props} mutationMode="pessimistic" mutationOptions={{ onSuccess }} actions={<BackButton />}>
            <SimpleForm>
                <ReferenceInput source="departmentID" reference="departments" filter={{ departmentsByTypeAndName: { type: "Department" } }} perPage={100}>
                    <SelectInput source="name" label="Department" optionText={record => <DepartmentPlusSiteField record={record} />} />
                </ReferenceInput>
            </SimpleForm>
        </Edit>)
};

export const PreferenceShow = (props) => {
    return (
        <Show {...props} actions={<BackButton />}>
            <SimpleShowLayout>
                <ReferenceField source="rotationID" reference="rotations" label="Rotation" />
                <ReferenceField source="departmentID" reference="departments" label="Department" />
                <NumberField source="priority" />
            </SimpleShowLayout>
        </Show>)
};




const PreferenceList = (props) => {
    return (
        <List {...props}>
            <Datagrid>
                <ReferenceField source="departmentID" reference="departments" label="Department">
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField source="traineeID" reference="trainees" label="Trainee" link="show">
                    <TextField source="name" />
                </ReferenceField>
                <NumberField source="priority" />
                <EditButton />
            </Datagrid>
        </List>
    );
};