import * as React from 'react';
import { Box, Typography, CircularProgress, Chip, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { useGetList, Form, useRefresh, CreateButton, Button, SelectInput, useGetOne, ExportButton, downloadCSV, useStore, useNotify } from 'react-admin';
import { API, Auth } from 'aws-amplify';
import { useWatch } from 'react-hook-form';
import jsonExport from 'jsonexport/dist';
import { TraineeExportButton } from './TraineeExportButton';
import { is_preference_white_text, get_color_code, get_num_of_preferences } from './variable_preferences';


export const RotationDataField = ({ currentRotation }) => {
    var chips = []
    for (let priority = 1; priority <= process.env.REACT_APP_NUM_OF_PREFRENCES; priority++) {
        const text_color = is_preference_white_text(priority) ? 'white' : 'black'
        chips.push(<Chip sx={{ backgroundColor: get_color_code(priority), color: text_color, m: 1 }} label={get_num_of_preferences(priority, currentRotation)} />)
    }
    chips.push(<Chip sx={{ backgroundColor: '#11111f', color: "white", m: 1 }} label={get_num_of_preferences(0, currentRotation)} />)

    return <Typography>
        {chips}
    </Typography>
}

const exporter = (records, fetchRelatedRecords, dataProvider, rotationID) => {
    // console.log(records);
    Promise.all(records.map(async trainee => {
        const { id, Preferences, cohort, cohortID, Seats, PublishedSeats, manualOverride, manualOverrideID, createdAt, type, updatedAt, ownerID, litigationSeatCompleted, bankingSeatCompleted, desiredGraduatingSeatID, desiredGraduatingSeat, traineeDiary, ...traineeForExport } = trainee; // omit backlinks and author
        const preferences = Array(3);
        for (var preference in Preferences.items) {
            if (Preferences.items[preference].rotationID == rotationID) {
                preferences[Preferences.items[preference].priority - 1] = await dataProvider.getOne('departments', { id: Preferences.items[preference].departmentID })
            }
        }
        let assignedSeat;
        for (var seat in Seats.items) {
            if (Seats.items[seat].rotationID == rotationID) {
                assignedSeat = await dataProvider.getOne('departments', { id: Seats.items[seat].departmentID });
            }
        }
        return {
            ...traineeForExport,
            '1st Preference': preferences[0]?.data.name,
            '2nd Preference': preferences[1]?.data.name,
            '3rd Preference': preferences[2]?.data.name,
            'Assigned Seat': assignedSeat?.data.name,
            'Cohort Start Date': cohort.startDate,
            'Manual Override': manualOverride?.name
        };
    })).then(traineesForExport => {
        jsonExport(traineesForExport, {
            headers: ['name', 'email', 'Cohort Start Date', '1st Preference', '2nd Preference', '3rd Preference', 'Manual Override', 'Assigned Seat'] // order fields in the export
        }, (err, csv) => {
            downloadCSV(csv, 'trainees'); // download as 'posts.csv` file
        });
    });
};


export const RotationFilterForm = ({ filter, setFilters, isLoading }) => {

    const refresh = useRefresh();
    const notify = useNotify();
    const [assignSeatsLoading, setAssignSeatsLoading] = React.useState(false)
    const [publishSeatsLoading, setPublishSeatsLoading] = React.useState(false)
    const rotationsList = useGetList('rotations', { filter: { rotationsByTypeAndStartDateAdmin: { type: "Rotation" } } });
    var rotations = rotationsList.data
    rotations?.sort((a, b) => (a.startDate > b.startDate) ? 1 : ((b.startDate > a.startDate) ? -1 : 0))
    const currentRotation = rotations?.at(-1)
    const onSubmit = (values) => {
        if (Object.keys(values).length > 0) {
            setFilters(values);
        }
    };

    const validateStartDate = (values) => {
        const errors = {};
        if (!values.rotation) {
            errors.rotation = "Must select a rotation"
        }
        return errors
    }

    const assignSeats = () => {
        const apiName = 'assignSeats';
        const path = '/assignseats';

        let jwt;
        Auth.currentSession().then(data => {
            // console.log(data)
            // console.log(data.idToken)
            const myInit = {
                headers: {
                    Authorization: data.idToken.jwtToken
                }, // OPTIONAL
                response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
                queryStringParameters: {
                    'rotationID': filter
                }
            };
            setAssignSeatsLoading(true);
            API.get(apiName, path, myInit).then(() => { setAssignSeatsLoading(false); refresh() }).catch(error => { setAssignSeatsLoading(false); notify(error.response.data, { type: 'error' }); })
        })
    }

    const publishSeats = () => {
        const apiName = 'publishSeats';
        const path = '/publishseats';

        let jwt;
        Auth.currentSession().then(data => {
            // console.log(data)
            // console.log(data.idToken)
            const myInit = {
                headers: {
                    Authorization: data.idToken.jwtToken
                }, // OPTIONAL
                response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
                queryStringParameters: {
                    'rotationID': filter
                }
            };
            setPublishSeatsLoading(true);
            API.get(apiName, path, myInit).then(() => { setPublishSeatsLoading(false); refresh() }).catch(error => { setPublishSeatsLoading(false); notify(error.response.data, { type: 'error' }); })
        })
    }

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    return (
        <>
            <Form onSubmit={onSubmit} validate={validateStartDate} defaultValues={{ rotation: filter }}>
                <Box display="flex" alignItems="stretch" alignContent='space-between' mb={1}>
                    {/* <Box display="flex" alignItems="flex-end" mb={1} sx={{ flexGrow: 1 }}>
                    <Box component="span" mr={2} >
                        <ReferenceInput source="rotation" reference='rotations' filter={{ rotationsByTypeAndStartDate: { type: "Rotation" } }}  >
                            <SelectInput label="Rotation Date" />
                        </ReferenceInput>
                    </Box>
                    <Box component="span" mr={2} mb={3.5}>
                        <Button size='large' variant="outlined" color="primary" type="submit">
                            Go
                        </Button>
                    </Box>
                </Box> */}
                    <Box display="flex" flexDirection="column" justifyContent='center' mb={1} sx={{ flexGrow: 1 }}>
                        {isLoading ? null : <RotationDataField currentRotation={currentRotation} />}
                    </Box>

                    <Box display="flex" justifyContent='center' alignItems="flex-end" mb={1}>
                        <Box component="span" mr={2} >
                            {isLoading ? null : <TraineeExportButton exporter={exporter} rotationID={filter} size='large' variant="outlined" color="primary" resource="trainees" />}
                        </Box>
                        <Box component="span" mr={2}>
                            <CreateButton label="Add Trainee" size='large' variant="outlined" color="primary" resource="trainees" />
                        </Box>
                        <Box component="span" mr={2}>
                            <Button size='large' variant="outlined" color="primary" onClick={assignSeats} disabled={currentRotation?.published || assignSeatsLoading}>
                                {assignSeatsLoading ? <CircularProgress size="26px" /> : "Assign seats"}
                            </Button>
                        </Box>

                        <Box component="span" mr={2} >
                            <Button size='large' variant="outlined" color="primary" onClick={handleClickOpen} disabled={currentRotation?.published || assignSeatsLoading}>
                                {publishSeatsLoading ? <CircularProgress size="26px" /> : "Publish seats"}
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Form>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogTitle id="alert-dialog-title">
                    {"Publish seats"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Publishing seats will freeze the currently assigned seats, make them visible to the trainees and email them notifying them of their seat for the next rotation.
                        <br /><br />It will also update the seat supervisors on the trainee views, so make sure you have finished entering any of these you wish to before publishing.
                        <br /><br />This process is <b>irreversible</b>. Are you sure you want to continue?
                    </DialogContentText>
                </DialogContent >
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                    <Button onClick={() => { handleClose(); publishSeats(); }} autoFocus>
                        Continue
                    </Button>
                </DialogActions>
            </Dialog >
        </>
    );
};