import '@aws-amplify/ui-react/styles.css';
import {
    List,
    Datagrid,
    SimpleForm,
    Create,
    Edit,
    EditButton,
    TextField,
    TextInput
} from "react-admin";
import { PostPagination } from './Trainee';


export const SitesList = (props) => {

    return (
        <List {...props} exporter={false} pagination={<PostPagination />}>
            <Datagrid >
                <TextField source="name" />
                <EditButton />
            </Datagrid>
        </List>
    );
};

export const SitesEdit = (props) => (
    <Edit {...props}>
        <SimpleForm >
            <TextInput source="name" />
        </SimpleForm>
    </Edit>
);

export const SitesCreate = (props) => (
    <Create {...props} >
        <SimpleForm >
            <TextInput source="name" />
        </SimpleForm>
    </Create>
);
