/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://wpp8orxygg.execute-api.eu-west-2.amazonaws.com/fieldfish",
            "region": "eu-west-2"
        },
        {
            "name": "assignSeats",
            "endpoint": "https://if6oza9fa8.execute-api.eu-west-2.amazonaws.com/fieldfish",
            "region": "eu-west-2"
        },
        {
            "name": "publishSeats",
            "endpoint": "https://nrtizz5f54.execute-api.eu-west-2.amazonaws.com/fieldfish",
            "region": "eu-west-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://firhtrdmvvgq3ii7oj5ojcqlzm.appsync-api.eu-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "eu-west-2:bc9c16f5-e145-42bc-9122-503a4712cf76",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_JI2TIbyX1",
    "aws_user_pools_web_client_id": "3gadvog86mtomopa8d5utb50a5",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "optiseatb36133a8b081486898e91560fcd6852c161211-fieldfish",
    "aws_user_files_s3_bucket_region": "eu-west-2"
};


export default awsmobile;
